import axios from "axios";
import { PAY_URL } from "../../../BaseURL";
import toast from "react-hot-toast";



export const DownloadSample = ({ url, bodyFormData, filename }) => {
    const myurl = `${PAY_URL}/user/${url}`;
    axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((response) => {
        console.log(response.data, '@@@')
        if (response.data.success === true) {
            const fileUrl = response.data.data.file;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', `${filename}.xlsx`);
            document.body.appendChild(link);
            link.click();
            toast.success(response.data.message);
        }
    }).catch((error) => {
        console.log(error, '!!!!');
        toast.error(error?.response.data?.message || 'Something went wrong');
    })
}

export const ImportFile = ({ url, bodyFormData }) => {
    const myurl = `${PAY_URL}/user/${url}`;
    axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((response) => {
        console.log(response.data, '@@@')
        if (response.data.success === true) {
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
    }).catch((error) => {
        console.log(error, '!!!!');
        toast.error(error?.response.data?.message || 'Something went wrong');
    })
}
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Collapse, Dropdown, Modal } from 'react-bootstrap';
import { Pagination, Search } from '../Table';
import Loader from '../Pages/Loader';
import CopyRight from '../Pages/CopyRight';
import moment from 'moment';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDepartment } from '../../../Store/User/Department/UserDepartment';
import { MultiSelect } from 'react-multi-select-component';
import calculateMonthRange from '../Components/Helper/calculateMonthDays';
import DropDonwlLimit from '../Components/DropDonwlLimit';

const LoanIssueReport = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setlimit] = useState(10);
  const [openFilter, setOpenFilter] = useState(false);
  const [disable, setDisable] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [modalData, setModalData] = useState({
    remark: "",
    oRemark: ""
  });
  const [filter, setFilter] = useState({
    month: '',
    startDate: '',
    endDate: '',
  });
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const [show, setShow] = useState(false);
  const [month, setMonth] = useState('');

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }

    if (disable === true) {
      setEntity([]);
      getIssueReport();
    }
  }, [disable, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getUserDepartment()),
        ]);
      } catch (error) {
        console.log(error, 'Error fetching data');
      }
    }
    fetchData();
  }, [dispatch]);


  useEffect(() => {
    getIssueReport();
  }, [filter.month, selectedDepartment, filter.startDate, filter.endDate]);

  const deapartmentData = useSelector(state => state?.getUserDepartment?.user?.data);

  const commentsData = useMemo(() => {
    let computedComments = [...entity];
    if (search) {
      computedComments = computedComments.filter(
        (loan) =>
          loan?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          loan?.card_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          loan?.employee_id?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }

    if (filter.month !== '') {
      computedComments = computedComments.filter((earn) => earn.e_month === parseInt(filter.month));
    }
    if (filter.startDate && filter.endDate) {
      const startDate = new Date(filter.startDate);
      const endDate = new Date(filter.endDate);
      computedComments = computedComments.filter(
        (e) => {
          const employeeDate = new Date(e.date);
          return employeeDate >= startDate && employeeDate <= endDate;
        });
    }
    setTotalItems(computedComments.length);
    return computedComments?.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, limit, entity, month, filter.month, filter.startDate, filter.endDate]);

  const getIssueReport = () => {
    const myurl = `${PAY_URL}/user/generate-loan-issue-report`;
    const bodyFormData = new URLSearchParams();
    if (selectedDepartment.length > 0) {
      bodyFormData.append("department", JSON.stringify(selectedDepartment.map(department => department.label)));
    }
    axios({
      method: "POST",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
      data: bodyFormData,
    })
      .then((response) => {
        if (response?.data?.success) {
          const data = response?.data?.data;
          console.log(data, 'hh')
          const filteredData = data?.filter(e =>
            e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID')
          );
          setEntity(filteredData);
          setDisable(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
        console?.log("Errors", error);
      });
  }

  const handleRefresh = () => {
    setSearch('');
    setCurrentPage(1);
    setFilter({ month: '', startDate: '', endDate: '' });
    setSelectedDepartment([]);
    setMinDate('');
    setMaxDate('');
  }

  const handleDepartment = (e) => {
    setSelectedDepartment(e);
  }

  const handleFilter = (e) => {
    const { name, value } = e.target;

    if (name === "month") {
      setFilter({
        ...filter,
        month: value,
        startDate: '',
        endDate: '',
      });
      setSelectedDepartment([]);
      const dateRange = calculateMonthRange(value);
      setMinDate(dateRange.min);
      setMaxDate(dateRange.max);
    } else {
      setFilter({ ...filter, [name]: value });
    }

    getIssueReport();
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (info) => {
    setModalData({
      remark: info?.remark,
      oRemark: info?.other_remark
    })
    setShow(true);
  }

  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">Loan Issue Report Management</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Loan Issue Report Management</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row filter-row justify-content-end">
            <div className='col-md-4 col-sm-12'>
              <div className="row justify-content-start">
                <div className="col-md-12 mb-2">
                  <select className="form-select form-control" name="month" value={filter.month} onChange={handleFilter}
                    aria-label="Default select example">
                    <option value=''>Select Month</option>
                    <option value='1'>January</option>
                    <option value='2'>February</option>
                    <option value='3'>March</option>
                    <option value='4'>April</option>
                    <option value='5'>May</option>
                    <option value='6'>June</option>
                    <option value='7'>July</option>
                    <option value='8'>August</option>
                    <option value='9'>September</option>
                    <option value='10'>October</option>
                    <option value='11'>November</option>
                    <option value='12'>December</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="row justify-content-end">
                <div className="col-md-12 mb-2">
                  <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                    <div className="seletePage d-flex gap-2 align-items-center ">
                      <p className="mb-0">Rows per page:</p>
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <DropDonwlLimit limit={limit} onLimitChange={(val) => setlimit(val)} />
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <div className="refreshPage">
                            <button className="btn btn-refresh" type='button' onClick={() => setOpenFilter(!openFilter)}
                              aria-controls="example-collapse-text"
                              aria-expanded={openFilter}>
                              <i className="fa-solid fa-filter"></i>
                            </button>

                          </div>
                          <div className="refreshPage">
                            <button className="btn btn-refresh" type='butotn' onClick={handleRefresh}>
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tableSearch">
                      <form action="">
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }} />
                        <span className="search">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <Collapse in={openFilter} >
            <div className='row filter-row mt-2'>
              <div className='col-md-3 col-lg-3 col-sm-12'>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    {deapartmentData && deapartmentData.length > 0 ? (
                      <>
                        <label className="form-label">Department</label>

                        <MultiSelect
                          name="department"
                          options={deapartmentData.map(e => ({ label: e.name, value: e._id }))}
                          value={selectedDepartment}
                          onChange={handleDepartment}
                          labelledBy="Select Department"
                        />
                      </>
                    ) : (
                      <p>Loading departments...</p>
                    )}
                  </div>

                </div>
              </div>

              <div className='col-md-3 col-lg-3 col-sm-12'>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label className="form-label">Start Date</label>
                    <input type="date" name="startDate" value={filter.startDate} min={minDate} max={maxDate}
                      onChange={handleFilter} className='form-control' />
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-lg-3 col-sm-12'>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label className="form-label">End Date</label>
                    <input type="date" name="endDate" value={filter.endDate} min={minDate} max={maxDate}
                      onChange={handleFilter} className='form-control' />
                  </div>
                </div>
              </div>
            </div>
          </Collapse>

          {disable === false ? (
            <div className="card p-3 ">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>Date</th>
                          <th>VNO.</th>
                          <th>Name</th>
                          <th>ID</th>
                          <th>CardId</th>
                          <th>Department</th>
                          <th>Amount</th>
                          <th>Term</th>
                          <th>Installment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commentsData?.map((elem, i) =>
                          <tr key={elem?._id}>
                            <td>{(currentPage - 1) * limit + i + 1}</td>
                            <td>{moment(elem?.date).format('YYYY-MM-DD')}</td>
                            <td>{elem?.voucher_no}</td>
                            <td>{elem?.full_name}</td>
                            <td>{elem?.employee_id}</td>
                            <td>{elem?.card_no}</td>
                            <td>{elem?.department}</td>
                            <td>{elem?.loan_amount}</td>
                            <td>{elem?.loan_terms}</td>
                            <td>{elem?.installment_amount === null ? '0' : elem.installment_amount}</td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan={6}></td>
                          <th>Total</th>
                          <th>
                            {commentsData?.reduce((total, elem) => total + (elem?.loan_amount || 0), 0)}
                          </th>
                          <td colSpan={2}></td>
                        </tr>

                        {commentsData?.length === 0 ? (
                          <tr>
                            <td colspan="999">
                              <div className="no-table-data">
                                No Data Found!
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="row align-center mt-3 user-account">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                      <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                        aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                      <div className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate">
                        <Pagination
                          total={totalItems}
                          itemsPerPage={limit}
                          currentPage={currentPage}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <Loader />}

          <CopyRight />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className='mb-4'>
            <h3 className='text-center'>Remark</h3>
            <div className='d-flex justify-content-center p-3'>
              <p>{modalData.remark === '' ? 'No remark added' : modalData.remark}</p>
            </div>
          </div>

          <div className=''>
            <h3 className='text-center'>Other Remark</h3>
            <div className='d-flex justify-content-center p-3'>
              <p>{modalData.oRemark === '' ? 'No other remark added' : modalData.oRemark}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default LoanIssueReport
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Pagination, Search } from '../Table';
import Loader from '../Pages/Loader';
import CopyRight from '../Pages/CopyRight';
import moment from 'moment';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDepartment } from '../../../Store/User/Department/UserDepartment';
import { MultiSelect } from 'react-multi-select-component';
import DropDonwlLimit from '../Components/DropDonwlLimit';

const LoanStatusReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [limit, setlimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([dispatch(getUserDepartment())]);
      } catch (error) {
        console.log(error, 'Error fetching data');
      }
    };
    fetchData();
  }, [dispatch]);

  const departmentData = useSelector(state => state?.getUserDepartment?.user?.data);

  const commentsData = useMemo(() => {
    let computedComments = [...entity];
    if (search) {
      computedComments = computedComments.filter(
        (loan) =>
          loan?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          loan?.card_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          loan?.employee_id?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, limit, entity]);

  const getStatusReport = () => {
    const { startDate, endDate } = filter;
    const bodyFormData = new URLSearchParams();
    if (selectedDepartment.length > 0) {
      bodyFormData.append("department", JSON.stringify(selectedDepartment.map(department => department.label)));
    }
    bodyFormData.append("startDate", startDate);
    bodyFormData.append("endDate", endDate);

    setLoading(true);
    axios({
      method: "POST",
      url: `${PAY_URL}/user/generate-loan-status-report`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN'),
      },
      data: bodyFormData,
    })
      .then((response) => {
        if (response?.data?.success) {
          const data = response?.data?.data;
          const filteredData = data.filter(e => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID'));
          setEntity(filteredData);
          setShowTable(true); // Show table after data is loaded
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error);
        console?.log("Errors", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApplyFilter = () => {
    getStatusReport();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleDepartment = (e) => {
    setSelectedDepartment(e);
  };

  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      getStatusReport();
    }
  }, [selectedDepartment]);

  const handleRefresh = () => {
    setSearch('');
    setCurrentPage(1);
    setSelectedDepartment([]);
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={() => setIsSidebarOpen(!isSidebarOpen)} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">Loan Status Report Management</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/user/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Loan Status Report Management</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row filter-row justify-content-start mb-3">
            <div className="col-md-3 col-sm-12 mb-2">
              <label className="form-label">Start Date</label>
              <input
                type="date"
                name="startDate"
                value={filter.startDate}
                onChange={handleFilterChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2">
              <label className="form-label">End Date</label>
              <input
                type="date"
                name="endDate"
                value={filter.endDate}
                onChange={handleFilterChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3 col-sm-12 mb-2 d-flex align-items-end">
              <button className="btn btn-success w-100" onClick={handleApplyFilter} disabled={!(filter.startDate && filter.endDate)}>Apply</button>
            </div>
          </div>

          <Collapse in={showTable}>
            <div className="row filter-row my-2">
              <div className="d-flex justify-content-between align-items-center w-100">

                <div className="col-md-3 col-lg-3 col-sm-12">
                  {departmentData && departmentData.length > 0 ? (
                    <>
                      <label className="form-label">Department</label>
                      <MultiSelect
                        name="department"
                        options={departmentData.map(e => ({ label: e.name, value: e._id }))}
                        value={selectedDepartment}
                        onChange={handleDepartment}
                        labelledBy="Select Department"
                      />
                    </>
                  ) : (
                    <p>Loading departments...</p>
                  )}
                </div>

                <div className="col-md-8 col-lg-8 col-sm-12 d-flex justify-content-end align-items-center flex-wrap gap-2">
                  <div className="d-flex gap-2 align-items-center">
                    <p className="mb-0">Rows per page:</p>
                    <DropDonwlLimit limit={limit} onLimitChange={(val) => setlimit(val)} />

                  </div>
                  <div className="refreshPage">
                    <button className="btn btn-refresh" type='butotn' onClick={handleRefresh}>
                      <i className="fa-solid fa-arrows-rotate"></i>
                    </button>
                  </div>
                  <div className="tableSearch">
                    <form>
                      <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      />
                      <span className="search">
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </span>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>


          {/* Table Data */}
          {loading ? <Loader /> : (showTable &&
            <div className="card p-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>Name</th>
                          <th>ID.</th>
                          <th>CardId</th>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Opening</th>
                          <th>Issued Loan</th>
                          <th>Received Loan</th>
                          <th>Balance Loan</th>
                          <th>Inst. Amt.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commentsData?.map((elem, i) => (
                          <tr key={elem?._id}>
                            <td>{i + 1}</td>
                            <td>{elem?.full_name || "N/A"}</td>
                            <td>{elem?.employee_id || "N/A"}</td>
                            <td>{elem?.card_no || "N/A"}</td>
                            <td>{elem?.designation || "N/A"}</td>
                            <td>{elem?.department || "N/A"}</td>
                            <td>{elem?.open_balance}</td>
                            <td>{elem?.totalLoanAmt}</td>
                            <td>{elem?.receive_amount}</td>
                            <td>{elem?.balance_amount}</td>
                            <td>{elem?.installment_amount}</td>
                          </tr>
                        ))}

                        {commentsData?.length === 0 ? (
                          <tr>
                            <td colSpan="999">
                              <div className="no-table-data">
                                No Data Found!
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="row align-center mt-3 user-account">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                      <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                        aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                      <div className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate">
                        <Pagination
                          total={totalItems}
                          itemsPerPage={limit}
                          currentPage={currentPage}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <CopyRight />
      </div>
    </div>
  );
};

export default LoanStatusReport;

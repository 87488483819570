import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import DecryptCode from '../Components/DecryptCode';

const Sidebar = () => {

    const location = useLocation();
    const [subMenu, setSubMenu] = useState(false);
    const [subMenu2, setSubMenu2] = useState(false);
    const [subPay, setSubPay] = useState(false);
    const descryptVal = DecryptCode();

    const handlePayroll = () => {
        setSubPay(!subPay);
    }

    const handleAttendance = () => {
        setSubMenu(!subMenu);
    }

    const handleReport = () => {
        setSubMenu2(!subMenu2);
    }

    useEffect(() => {
        if (
            location.pathname === "/user/monthly-attendance-management" ||
            location.pathname === "/user/manage-monthly-attendance" ||

            location.pathname === "/user/daily-attendance-management" ||
            location.pathname === "/user/manage-daily-attendance" ||


            location.pathname === "/user/daily-attendance-report"
        ) {
            setSubMenu(true);
        }

        if (location.pathname === "/user/report-management" ||
            location.pathname === "/user/view-report" ||
            location.pathname === '/user/report-list' ||
            location.pathname === '/user/earning-report' ||
            location.pathname === '/user/deduction-report' ||
            location.pathname === '/user/loan-report' || 
            location.pathname === '/user/loan-issue-report' || 
            location.pathname === '/user/loan-status-report' 

        ) {
            setSubMenu2(true);
        }

        if (
            location.pathname === "/user/bank-management" ||
            location.pathname === "/user/manage-bank" ||

            location.pathname === "/user/group-management" ||
            location.pathname === "/user/manage-group" ||

            location.pathname === "/user/department-management" ||
            location.pathname === "/user/manage-department" ||

            location.pathname === "/user/designation-management" ||
            location.pathname === "/user/manage-designation" ||

            location.pathname === "/user/shift-management" ||
            location.pathname === "/user/manage-shift" ||

            location.pathname === "/user/authorized-person-management" ||
            location.pathname === "/user/manage-authorized-person" ||

            location.pathname === '/user/skill-management' ||
            location.pathname === '/user/manage-skill' ||

            location.pathname === '/user/employee-type-management' ||
            location.pathname === '/user/manage-employee-type'
        ) {
            setSubPay(true);
        }

    }, [location.pathname])

    return (
        <div className="sidebar userSidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu mt-3">
                    <ul className="sidebar-vertical">

                        <li className={`${location.pathname === "/user/dashboard" ? "active" : ""}`}>
                            <Link className="" to="/user/dashboard"><i className="fa-solid fa-gauge"></i>
                                <span>Dashboard</span></Link>
                        </li>

                        <li className="submenu">
                            {/* eslint-disable jsx-a11y/anchor-is-valid */}
                            <a className={`${subPay === true ? 'subdrop' : ''}`} onClick={handlePayroll}><i className="fa-solid fa-money-bill-transfer"></i><span>Payroll Master</span> <span
                                className="menu-arrow"></span></a>
                            <ul style={{ display: subPay ? 'block' : 'none' }}>
                                <li className={` ${location.pathname === "/user/bank-management" ||
                                    location.pathname === "/user/manage-bank"
                                    ? "active" : ""}`}>
                                    <Link to="/user/bank-management">Bank</Link>
                                </li>

                                <li className={` ${location.pathname === "/user/group-management" ||
                                    location.pathname === "/user/manage-group"
                                    ? "active" : ""}`}>
                                    <Link to="/user/group-management">Group</Link>
                                </li>

                                <li className={` ${location.pathname === "/user/department-management" ||
                                    location.pathname === "/user/manage-department"
                                    ? "active" : ""}`}>
                                    <Link to="/user/department-management">Department</Link>
                                </li>

                                <li className={` ${location.pathname === "/user/designation-management" ||
                                    location.pathname === "/user/manage-designation"
                                    ? "active" : ""}`}>
                                    <Link to='/user/designation-management'>Designations</Link>
                                </li>

                                <li className={` ${location.pathname === "/user/shift-management" ||
                                    location.pathname === "/user/manage-shift" ? "active" : ""}`}>
                                    <Link to='/user/shift-management' >Shift</Link>
                                </li>

                                <li className={` ${location.pathname === "/user/authorized-person-management" ||
                                    location.pathname === "/user/manage-authorized-person"
                                    ? "active" : ""}`}>
                                    <Link to='/user/authorized-person-management'>Auth Person</Link>
                                </li>

                                <li className={` ${location.pathname === "/user/skill-management" ||
                                    location.pathname === "/user/manage-skill"
                                    ? "active" : ""}`}>
                                    <Link to='/user/skill-management'>Skill</Link>
                                </li>

                                <li className={` ${location.pathname === "/user/employee-type-management" ||
                                    location.pathname === "/user/manage-employee-type"
                                    ? "active" : ""}`}>
                                    <Link to='/user/employee-type-management'>Employee Type</Link>
                                </li>

                            </ul>
                        </li>

                        <li className={` ${location.pathname === "/user/employee-management" ||
                            location.pathname === '/user/manage-employee' ? "active" : ""}`}>
                            <Link to="/user/employee-management"><i className="fa-solid fa-user"></i><span>Employee</span></Link>
                        </li>

                        <li className={` ${location.pathname === "/user/workingday-management" ||
                            location.pathname === '/user/manage-workingday' ? "active" : ""}`}>
                            <Link to="/user/workingday-management"><i className="fa-solid fa-calendar-check"></i><span>Working Day</span></Link>
                        </li>

                        {descryptVal === false ? (
                            <li className={` ${location.pathname === "/user/salary-management" ||
                                location.pathname === '/user/manage-salary' ? "active" : ""}`}>
                                <Link to='/user/salary-management'><i className="fa-solid fa-money-bill"></i><span>Salaries</span></Link>
                            </li>
                        ) : null}

                        <li className="submenu">
                            <a to="#" className={`${subMenu === true ? 'subdrop' : ''}`} onClick={handleAttendance}><i className="fa-solid fa-clipboard-user"></i><span> Attendance </span> <span
                                className="menu-arrow"></span></a>
                            <ul style={{ display: subMenu ? 'block' : 'none' }}>
                                <li className={` ${location.pathname === "/user/daily-attendance-management" ||
                                    location.pathname === '/user/manage-daily-attendance' ? "active" : ""}`}>
                                    <Link to="/user/daily-attendance-management">Daily</Link>
                                </li>
                                {/* <li className={` ${location.pathname === "/user/daily-attendance-report" ? "active" : ""}`}>
                                    <Link to="/user/daily-attendance-report">Daily Report</Link>
                                </li> */}
                                <li className={` ${location.pathname === "/user/monthly-attendance-management" ||
                                    location.pathname === '/user/manage-monthly-attendance' ? "active" : ""}`}>
                                    <Link to="/user/monthly-attendance-management"> Monthly</Link>
                                </li>
                            </ul>
                        </li>

                        <li className={` ${location.pathname === "/user/earning-management" ||
                            location.pathname === '/user/manage-earning' ? "active" : ""}`}>
                            <Link to="/user/earning-management"><i className="fa-solid fa-coins"></i><span>Earning</span></Link>
                        </li>

                        <li className={` ${location.pathname === "/user/deduction-management" ||
                            location.pathname === '/user/manage-deduction' ? "active" : ""}`}>
                            <Link to="/user/deduction-management"><i className="fa-solid fa-hand-holding-dollar"></i><span>Deduction</span></Link>
                        </li>

                        <li className={` ${location.pathname === "/user/loan-management" ||
                            location.pathname === '/user/manage-loan' ? "active" : ""}`}>
                            <Link to="/user/loan-management"><i className="fa-solid fa-piggy-bank"></i><span>Loan Issue</span></Link>
                        </li>

                        <li className={` ${location.pathname === "/user/holiday-management" ||
                            location.pathname === '/user/manage-holiday' ? "active" : ""}`}>
                            <Link to='/user/holiday-management'><i className="fa-regular fa-calendar-days"></i><span>Holiday</span></Link>
                        </li>

                        {/* <li className={` ${location.pathname === "/user/report-management" || location.pathname === '/user/view-report' ? "active" : ""}`}>
                            <a to="/user/report-management"><i className="fa-regular fa-file-lines"></i><span>Report</span></a>
                        </li> */}

                        {descryptVal === false ? (
                            <li className="submenu">
                                <a className={`${subMenu2 === true ? 'subdrop' : ''}`} onClick={handleReport}><i className="fa-regular fa-file-lines"></i><span> Report </span> <span
                                    className="menu-arrow"></span></a>
                                <ul style={{ display: subMenu2 ? 'block' : 'none' }}>
                                    <li className={` ${location.pathname === "/user/report-management" ||
                                        location.pathname === '/user/view-report' ? "active" : ""}`}>
                                        <Link to="/user/report-management">Generate Report</Link>
                                    </li>
                                    <li className={`${location.pathname === '/user/report-list' ? 'active' : ''}`}>
                                        <Link to="/user/report-list">Report List</Link>
                                    </li>
                                    <li className={`${location.pathname === '/user/earning-report' ? 'active' : ''}`}>
                                        <Link to="/user/earning-report">Earning</Link>
                                    </li>
                                    <li className={`${location.pathname === '/user/deduction-report' ? 'active' : ''}`}>
                                        <Link to="/user/deduction-report">Deduction</Link>
                                    </li>
                                    <li className={`${location.pathname === '/user/loan-report' ? 'active' : ''}`}>
                                        <Link to="/user/loan-report">Loan Receive</Link>
                                    </li>
                                    <li className={`${location.pathname === '/user/loan-issue-report' ? 'active' : ''}`}>
                                        <Link to="/user/loan-issue-report">Loan Issue</Link>
                                    </li>
                                    <li className={`${location.pathname === '/user/loan-status-report' ? 'active' : ''}`}>
                                        <Link to="/user/loan-status-report">Loan Status</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import { DownloadSample } from './DownloadSample';

const ImportDailyAttendance = ({ openDaily, handleHideDailyModal, title, url, getDailyAttendance }) => {

    const [daily, setDaily] = useState({ month: '', password: '', showEye: true, file: null });
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);

    const handleChange = (e) => {
        setDaily({ ...daily, [e.target.name]: e.target.value });
    }

    const handleFile = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setDaily({ ...daily, file: file });
            setError(prevError => ({ ...prevError, file_err: '' }));
        } else {
            setError(prevError => ({ ...prevError, file_err: 'Please upload a valid .xlsx file' }));
            setDaily({ ...daily, file: null });
        }
    }

    const handleSubmit = () => {
        if (validation()) {
            const bodyFormData = new FormData();
            bodyFormData.append('password', daily.password);
            bodyFormData.append('month', daily.month);
            bodyFormData.append('file', daily.file);
            bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
            bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));

            setDisable(true);

            axios({
                method: 'post',
                url: `${PAY_URL}/user/${url}`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
            }).then((response) => {
                if (response.data.success === true) {
                    toast.success(response.data.message);
                    handleHideDailyModal();
                    if(response.data.data.file) {
                        const fileUrl = response.data.data.file;
                        const link = document.createElement('a');
                        link.href = fileUrl;
                        link.setAttribute('download', 'DailyAttendance-Error-Report.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    }
                    setDaily({ month: '', password: '', showEye: true, file: null });
                    getDailyAttendance();
                }
                setDisable(false);
            }).catch((error) => {
                setDisable(false);
                toast.error(error?.response?.data?.message);
            });
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!daily?.month) {
            isValid = false;
            err['month_err'] = 'Please select month';
        }
        if (!daily?.password) {
            isValid = false;
            err['password_err'] = 'Please enter password';
        }
        if (!daily?.file) {
            isValid = false;
            err['file_err'] = 'Please select a file';
        }

        setError(err);
        return isValid;
    }

    return (
        <Modal show={openDaily} onHide={handleHideDailyModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=''>
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <div className="input-group">
                                <span className="input-group-text">Month </span>
                                <select className="form-select form-control" value={daily.month} onChange={handleChange} name='month'
                                    aria-label="Default select example">
                                    <option value=''>Select Month</option>
                                    <option value='1'>January</option>
                                    <option value='2'>February</option>
                                    <option value='3'>March</option>
                                    <option value='4'>April</option>
                                    <option value='5'>May</option>
                                    <option value='6'>June</option>
                                    <option value='7'>July</option>
                                    <option value='8'>August</option>
                                    <option value='9'>September</option>
                                    <option value='10'>October</option>
                                    <option value='11'>November</option>
                                    <option value='12'>December</option>
                                </select>
                                <div className='error'>{error?.month_err}</div>
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className="input-group">
                                <span className="input-group-text">Password</span>
                                <input
                                    type={daily?.showEye ? "password" : "text"}
                                    className="form-control position-relative"
                                    value={daily.password}
                                    name='password'
                                    onChange={handleChange}
                                />
                                {daily?.showEye ? (
                                    <div className='tranfer-eye' onClick={() => setDaily({ ...daily, showEye: false })}><i className="fa-solid fa-eye" /></div>
                                ) : (
                                    <div className='tranfer-eye' onClick={() => setDaily({ ...daily, showEye: true })}><i className="fa-solid fa-eye-slash" /></div>
                                )}
                            </div>
                            <div className='error'>{error?.password_err}</div>
                        </div>

                        <div className='col-12 mb-3'>
                            <div className="input-group">
                                <input className="form-control" type="file" name="file" onChange={handleFile} />
                            </div>
                            <div className='error'>{error?.file_err}</div>
                            {daily.file && <div className="file-name">Selected File: {daily.file.name}</div>}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHideDailyModal}>
                    Close
                </Button>
                <Button className='btn btn-success' onClick={handleSubmit} disabled={disable}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImportDailyAttendance;

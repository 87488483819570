import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import CopyRight from '../Pages/CopyRight';
import { MultiSelect } from 'react-multi-select-component';

const ManageEarning = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const [selectValue, setSelectValue] = useState('');

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const formattedDate = `${year}-${month}-${day}`;
    const dayOfWeek = daysOfWeek[currentDate.getDay()];

    const [earning, setEarning] = useState({
        date: formattedDate,
        dayOfWeek: dayOfWeek,
        voucher_no: "",
        ovoucher_no: "",
        type: "",
        remark: "",
        other_remark: "",
        amount: "",
    });

    const [employeeData, setEmployeeData] = useState([]);
    const [monthly, setMonthly] = useState([]);
    const [daily, setDaily] = useState([]);
    const [employAllData, setEmployAllData] = useState({
        designation: "",
        employee_id: "",
        card_no: "",
        department: "",
    });
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [dPre, setDPre] = useState('');
    const [mPre, setMPre] = useState('');

    const data = location.state;
    // console.log(data, '##@#@');

    useEffect(() => {
        if (location.state) {
            setEarning({
                date: moment(location.state.date).format('YYYY-MM-DD'),
                voucher_no: location.state.voucher_no,
                ovoucher_no: location.state.other_voucher_no,
                type: location.state.type,
                remark: location.state.remark,
                other_remark: location.state.other_remark,
                amount: location.state.amount,
            });
            setSelectValue(location.state.status);
            setSelectedEmployee([{
                label: `${location.state.employee.full_name} - ${location.state.employee.employee_id} - 
                ${location.state.employee?.adhar_no}`, value: location.state.employee._id
            }]);
        }

    }, [location.state]);

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
        getEmployee();
    }, [navigate, selectedEmployee]);

    useEffect(() => {
        const selectedDate = new Date(earning.date);
        const selectedDayOfWeek = daysOfWeek[selectedDate.getDay()];
        setEarning(prevState => ({ ...prevState, dayOfWeek: selectedDayOfWeek }));
        // eslint-disable-next-line
    }, [earning?.date]);


    const getEmployee = () => {
        const myurl = `${PAY_URL}/user/get-all-employee`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then(async (response) => {
            // console.log(response.data, '$$$')
            if (response?.data?.success) {
                const data = response?.data?.data
                const filterData = data.filter((e) => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID'));
                setEmployeeData(filterData);
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        });
    }

    useEffect(() => {
        const selectEmployee = employeeData?.find(emp => emp?._id === selectedEmployee[0]?.value);

        if (selectEmployee) {
            setEmployAllData({
                designation: selectEmployee?.designation?.name,
                card_no: selectEmployee?.card_no,
                employee_id: selectEmployee?.employee_id,
                department: selectEmployee?.salary_id?.department?.name
            });

            getMonthly(selectEmployee?._id)
            getDaily(selectEmployee?._id);
        }
        // eslint-disable-next-line
    }, [selectedEmployee, employeeData]);

    const getMonthly = (id) => {
        const myurl = `${PAY_URL}/user/get-monthly-attendance?id=${id}`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        })
            .then(async (response) => {
                if (response?.data?.success) {
                    const data = response?.data?.data
                    const filterData = data.filter((e) => e.firm_id?._id === localStorage.getItem('PAY_USER_FIRM_ID') &&
                        moment(e?.year?.start_year).format('YYYY') === moment(earning?.date).format('YYYY'));
                    setMonthly(filterData);
                } else {
                    toast.error(response?.data?.message || "Something went wrong");
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong");
                console?.log("Errors", error);
            });
    }

    const getDaily = (id) => {
        const myurl = `${PAY_URL}/user/get-daily-attendance?id=${id}`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        })
            .then(async (response) => {
                if (response?.data?.success) {
                    const data = response?.data?.data
                    const filterData = data.filter((e) => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID') &&
                        moment(e.date).year() === moment(earning.date).year());

                    setDaily(filterData);
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
            });
    }


    useEffect(() => {
        const selectedMonth = monthly.find(m => m?.month.toString() === (moment(earning.date).format('M')));
        setMPre(selectedMonth?.present_day)
    }, [earning?.date, monthly]);

    useEffect(() => {
        const selectedDaily = daily.filter(d => moment(d?.date).format('MM') === (moment(earning.date).format('MM')))
        setDPre(selectedDaily?.length);
    }, [earning?.date, daily])


    const handleRadioChange = (event) => {
        setSelectValue(event.target.checked);
    };

    const handleChange = (e) => {
        setEarning({ ...earning, [e.target.name]: e.target.value })
    }

    const selectedEmpChange = (e) => {
        if (e.length > 1) {
            e = e.slice(-1);
        }
        setSelectedEmployee(e);
    }

    const handleSubmit = async () => {
        if (validation()) {
            try {
                const form = new URLSearchParams();
                form.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
                form.append('employee', selectedEmployee[0]?.value);
                form.append('date', earning?.date);
                form.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
                const resSalary = await axios({
                    method: 'post',
                    url: `${PAY_URL}/user/employee-salary`,
                    data: form,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
                })
                if (resSalary.data.success) {

                    setDisable(true);
                    const bodyFormData = new URLSearchParams();
                    bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
                    bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
                    bodyFormData.append('employee', selectedEmployee[0]?.value);
                    bodyFormData.append('date', earning?.date);
                    bodyFormData.append('voucher_no', earning?.voucher_no);
                    bodyFormData.append('other_voucher_no', earning?.ovoucher_no);
                    bodyFormData.append('type', earning.type);
                    bodyFormData.append('remark', earning.remark);
                    bodyFormData.append('other_remark', earning.other_remark);
                    bodyFormData.append('amount', earning.amount);

                    if (data?._id) {
                        bodyFormData.append('id', data?._id);
                        bodyFormData?.append('status', selectValue);
                    }

                    axios({
                        method: 'post',
                        url: `${PAY_URL}/user/manage-earning`,
                        data: bodyFormData,
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
                    }).then((response) => {
                        if (response.data.success === true) {
                            toast.success(response.data.message)
                            navigate('/user/earning-management');
                        }
                        setDisable(false);
                    }).catch((error) => {
                        console.log(error);
                        toast.error(error.response.data.message);
                        setDisable(false);
                    })
                } else {
                    toast.error(resSalary.data.message);
                }
            } catch (error) {
                console.log(error, 'ERROR')
                toast.error(error?.response?.data?.message || 'Something went wrong');
            }
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!earning?.date) {
            isValid = false;
            err['date_err'] = 'Please select date'
        }

        if (!selectedEmployee?.length > 0) {
            isValid = false;
            toast.error('Please select employee');
        }

        if (!earning?.type) {
            isValid = false;
            err['type_err'] = 'Please select type'
        }

        if (!earning?.amount) {
            isValid = false;
            err['amount_err'] = 'Please enter amount'
        }

        setError(err);
        return isValid
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Earning</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to='/user/earning-management'>Earning Management</Link></li>
                                    <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Earning</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card p-3 basicform">
                        <div className=" mb-3 titleForm border-bottom">
                            <h4 className="">Earning</h4>
                        </div>

                        <div className="row card_id">

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="date" className="form-label">Date <span className='text-danger'> *</span></label>
                                        <input type="date" className="form-control" id="date"
                                            name='date' value={earning?.date} onChange={handleChange} disabled={data?._id ? 'disabled' : ''}
                                        />
                                        <div className='error'>{error?.date_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="date" className="form-label">Day</label>
                                        <input value={earning?.dayOfWeek} className="form-control" disabled />
                                    </div>
                                </div>
                            </div>

                            {data?._id ? (
                                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label htmlFor="voucher" className="form-label">Voucher No.</label>
                                            <input type="text" className="form-control" value={earning?.voucher_no} disabled />
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                        </div>

                        <div className='row card_id'>
                            <div className="col-md-8 col-lg-8 col-xxl-8 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="workDepartment" className="form-label">Employee - Id - Card Id - Aadhar card  <span className='text-danger'> *</span></label>
                                        {/* <MultiSelect
                                            options={employeeData?.map(e => ({ label: `${e.full_name} - ${e?.employee_id} - ${e?.adhar_no}`, value: e._id }))}
                                            onChange={selectedEmpChange}
                                            // isLoading={true}
                                            hasSelectAll={false}
                                            value={selectedEmployee}
                                            labelledBy="Select Employee"
                                        /> */}
                                        <MultiSelect
                                            options={employeeData?.map(e => ({
                                                label: `${e.full_name} - ${e.employee_id} - ${e.card_no ? e.card_no : '-'} - ${e.adhar_no}`,
                                                value: e._id,
                                                searchableLabel: `${e.full_name} ${e.employee_id} ${e.card_no || ''}`
                                            }))}
                                            onChange={selectedEmpChange}
                                            hasSelectAll={false}
                                            value={selectedEmployee}
                                            labelledBy="Select Employee"
                                            filterOptions={(options, filter) =>
                                                options.filter(({ searchableLabel }) =>
                                                    searchableLabel.toLowerCase().includes(filter.toLowerCase())
                                                )
                                            }
                                        />
                                        <div className='error'>{error?.employee_err}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row card_id'>
                            {selectedEmployee?.length > 0 ? (
                                <>
                                    <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                        <div className="cardNum">
                                            <div className="mb-3">
                                                <label className="form-label">Present Days</label>
                                                <input className="form-control" value={mPre === undefined ? dPre : mPre} disabled />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                        <div className="cardNum">
                                            <div className="mb-3">
                                                <label className="form-label">Designation</label>
                                                <input className="form-control" value={employAllData?.designation} disabled />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                        <div className="cardNum">
                                            <div className="mb-3">
                                                <label className="form-label">Department</label>
                                                <input className="form-control" value={employAllData?.department || '-'} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>


                        <div className="row card_id">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="Type" className="form-label">Type <span className='text-danger'> *</span></label>
                                        <select className="form-select form-control" aria-label="Default select example"
                                            onChange={handleChange} name='type' value={earning?.type}
                                        >
                                            <option value="">Select Type</option>
                                            <option value="Bonus">Bonus</option>
                                            <option value="Over Time">Over Time</option>
                                            <option value="Sunday_Present">Sunday Present</option>
                                            <option value="Full_Night_Present">Full Night Present</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <div className='error'>{error?.type_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="voucher" className="form-label">Other Voucher No.</label>
                                        <input type="text" className="form-control" id="voucher"
                                            onChange={handleChange} name='ovoucher_no' value={earning?.ovoucher_no}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="Amount" className="form-label">Amount <span className='text-danger'> *</span></label>
                                        <input type="number" className="form-control" id="Amount"
                                            onKeyDown={handleKeyDown} onWheel={(e) => e.target.blur()}
                                            onChange={handleChange} name='amount' value={earning?.amount}
                                        />
                                        <div className='error'>{error?.amount_err}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row card_id">
                            <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="remark" className="form-label">Remarks</label>
                                        <textarea className="form-control" placeholder="Comment here...." onChange={handleChange}
                                            name='remark' value={earning?.remark}
                                            id="remark"></textarea>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="remark" className="form-label">Other Remarks</label>
                                        <textarea className="form-control" placeholder="Comment here...."
                                            id="remark"
                                            onChange={handleChange} name='other_remark' value={earning?.other_remark}
                                        />
                                    </div>
                                </div>
                            </div> */}

                            {data?._id ? (
                                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label htmlhtmlFor="fileUpload" className="form-label">Status</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="row card_id mt-4 sm-mt-3">
                            <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3 d-flex gap-2">
                                        <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                                        <button className="btn btn-outline-secondary w-50" type='button' onClick={() => setEarning({
                                            employee: "",
                                            date: formattedDate,
                                            dayOfWeek: dayOfWeek,
                                            voucher_no: "",
                                            type: "",
                                            remark: "",
                                            other_remark: "",
                                            amount: "",
                                        })}> Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRight />
            </div>
        </div>
    )
}

export default ManageEarning
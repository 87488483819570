// import React, { useEffect, useState } from 'react'
// // import { useDispatch } from 'react-redux';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Header from '../Pages/Header';
// import Sidebar from '../Pages/Sidebar';
// import { PAY_URL } from '../../../BaseURL';
// import toast from 'react-hot-toast';
// import axios from 'axios';
// import moment from 'moment';
// import CopyRight from '../Pages/CopyRight';

// const ManageHoliday = () => {

//     const location = useLocation();
//     const navigate = useNavigate();
//     const [error, setError] = useState({});
//     const [disable, setDisable] = useState(false);
//     const [selectValue, setSelectValue] = useState('');

//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
//     const day = currentDate.getDate().toString().padStart(2, '0');
//     const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//     const formattedDate = `${year}-${month}-${day}`;
//     const dayOfWeek = daysOfWeek[currentDate.getDay()];
//     const monthName = new Intl.DateTimeFormat('en-IN', { month: 'long' }).format(currentDate);

//     const [holiday, setHoliday] = useState({
//         name: "",
//         day: dayOfWeek,
//         date: formattedDate,
//         month: monthName
//     });

//     useEffect(() => {
//         if (localStorage.getItem('PAY_USER_TOKEN') === null) {
//             navigate("/user/login");
//         }
//     }, [navigate]);

//     const data = location.state;
//     // console.log(data, '@@')

//     useEffect(() => {

//         if (location.state) {
//             setHoliday({
//                 name: location.state.name,
//                 day: location.state.day,
//                 date: moment(location.state.date).format('YYYY-MM-DD'),
//                 month: location.state.month
//             })

//             setSelectValue(location.state.status)
//         }

//     }, [location.state]);

//     useEffect(() => {
//         const selectedDate = new Date(holiday.date);
//         const selectedDayOfWeek = daysOfWeek[selectedDate.getDay()];
//         const newMonth = selectedDate.toLocaleString('default', { month: 'long' });
//         setHoliday(prevHoliday => ({ ...prevHoliday, month: newMonth, day: selectedDayOfWeek }));
//         // eslint-disable-next-line
//     }, [holiday.date]);


//     const handleChange = (e) => {
//         setHoliday({ ...holiday, [e.target.name]: e.target.value })
//     }

//     const handleRadioChange = (event) => {
//         setSelectValue(event.target.checked);
//     };

//     const handleSubmit = () => {
//         if (validation()) {
//             setDisable(true)
//             const bodyFormData = new URLSearchParams();
//             bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
//             bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

//             bodyFormData.append('name', holiday.name)
//             bodyFormData.append('day', holiday.day)
//             bodyFormData.append('date', holiday.date)
//             bodyFormData.append('month', holiday.month)

//             if (data?._id) {
//                 bodyFormData.append('id', data?._id);
//                 bodyFormData?.append('status', selectValue);
//             }

//             axios({
//                 method: 'post',
//                 url: `${PAY_URL}/user/manage-holiday`,
//                 data: bodyFormData,
//                 headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
//             }).then((response) => {
//                 // console.log(response?.data);
//                 if (response.data.success === true) {
//                     toast.success(response.data.message)
//                     navigate('/user/holiday-management');
//                 }
//                 setDisable(false);
//             }).catch((error) => {
//                 console.log(error);
//                 toast.error(error.response.data.message);
//                 setDisable(false);
//             })
//         }
//     }

//     const validation = () => {
//         let isValid = true;
//         let err = {};

//         if (!holiday.name) {
//             isValid = false;
//             err['name_err'] = 'Please enter name'
//         }

//         if (!holiday.date) {
//             isValid = false;
//             err['date_err'] = 'Please select date'
//         }

//         setError(err);
//         return isValid
//     }

//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const handleOpen = () => {
//         setIsSidebarOpen(!isSidebarOpen)
//     }

//     return (
//         <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
//             <Header handleOpen={handleOpen} />
//             <Sidebar />

//             <div className="page-wrapper">
//                 <div className="content container-fluid">
//                     <div className="page-header">
//                         <div className="row align-items-center">
//                             <div className="col">
//                                 <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Holiday</h3>
//                                 <ul className="breadcrumb">
//                                     <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
//                                     <li className="breadcrumb-item"><Link to='/user/holiday-management'>Holiday Management</Link></li>
//                                     <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Holiday Attendance</li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="card p-3 basicform">
//                         <div className=" mb-3 titleForm border-bottom">
//                             <h4 className="">Holiday</h4>
//                         </div>

//                         <div className="row card_id">
//                             <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
//                                 <div className="cardNum">
//                                     <div className="mb-3">
//                                         <label className="form-label">Name <span className='text-danger'> *</span></label>
//                                         <input type="text" className="form-control" name='name' value={holiday?.name} onChange={handleChange} />
//                                         <div className='error'>{error?.name_err}</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row card_id">

//                             <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
//                                 <div className="cardNum">
//                                     <div className="mb-3">
//                                         <label htmlFor="date" className="form-label">Date <span className='text-danger'> *</span></label>
//                                         <input type="date" className="form-control" id="date" name='date' onChange={handleChange} value={holiday?.date} />
//                                         <div className='error'>{error?.date_err}</div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
//                                 <div className="cardNum">
//                                     <div className="mb-3">
//                                         <label htmlFor="employeeID" className="form-label">Days</label>
//                                         <input value={holiday?.day} className="form-control" disabled />
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
//                                 <div className="cardNum">
//                                     <div className="mb-3">
//                                         <label htmlFor="employeeID" className="form-label">Month</label>
//                                         <input value={holiday?.month} className="form-control" disabled />
//                                     </div>
//                                 </div>
//                             </div>


//                             {data?._id ? (
//                                 <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
//                                     <div className="cardNum">
//                                         <div className="mb-3">
//                                             <label htmlhtmlFor="fileUpload" className="form-label">Status</label>
//                                             <div className="form-check form-switch">
//                                                 <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ) : null}
//                         </div>

//                         <div className="row card_id mt-4 sm-mt-3">
//                             <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
//                                 <div className="cardNum">
//                                     <div className="mb-3 d-flex gap-2">
//                                         <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
//                                         <button className="btn btn-outline-secondary w-50" type='button' onClick={() => setHoliday({
//                                             name: "",
//                                             day: dayOfWeek,
//                                             date: formattedDate,
//                                             month: monthName
//                                         })}>Reset</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <CopyRight />
//             </div>
//         </div>
//     )
// }

// export default ManageHoliday

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import axios from 'axios';
import moment from 'moment';
import CopyRight from '../Pages/CopyRight';

const ManageHoliday = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const [selectValue, setSelectValue] = useState('');

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const monthNumber = currentDate.getMonth() + 1;
    const day = currentDate.getDate().toString().padStart(2, '0');
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const formattedDate = `${year}-${monthNumber.toString().padStart(2, '0')}-${day}`;
    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const [holiday, setHoliday] = useState({
        name: "",
        day: dayOfWeek,
        date: formattedDate,
        month: monthNumber
    });

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
    }, [navigate]);

    const data = location.state;

    useEffect(() => {
        if (location.state) {
            setHoliday({
                name: location.state.name,
                day: location.state.day,
                date: moment(location.state.date).format('YYYY-MM-DD'),
                month: location.state.month
            });

            setSelectValue(location.state.status);
        }
    }, [location.state]);

    useEffect(() => {
        const selectedDate = new Date(holiday.date);
        const selectedDayOfWeek = daysOfWeek[selectedDate.getDay()];
        const newMonthNumber = selectedDate.getMonth() + 1;
        setHoliday(prevHoliday => ({ ...prevHoliday, month: newMonthNumber, day: selectedDayOfWeek }));
        // eslint-disable-next-line
    }, [holiday.date]);

    const handleChange = (e) => {
        setHoliday({ ...holiday, [e.target.name]: e.target.value });
    };

    const handleRadioChange = (event) => {
        setSelectValue(event.target.checked);
    };

    const handleSubmit = () => {
        if (validation()) {
            setDisable(true);
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
            bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

            bodyFormData.append('name', holiday.name);
            bodyFormData.append('day', holiday.day);
            bodyFormData.append('date', holiday.date);
            bodyFormData.append('month', holiday.month);

            if (data?._id) {
                bodyFormData.append('id', data?._id);
                bodyFormData?.append('status', selectValue);
            }

            axios({
                method: 'post',
                url: `${PAY_URL}/user/manage-holiday`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
            }).then((response) => {
                if (response.data.success === true) {
                    toast.success(response.data.message);
                    navigate('/user/holiday-management');
                }
                setDisable(false);
            }).catch((error) => {
                toast.error(error.response.data.message);
                setDisable(false);
            });
        }
    };

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!holiday.name) {
            isValid = false;
            err['name_err'] = 'Please enter name';
        }

        if (!holiday.date) {
            isValid = false;
            err['date_err'] = 'Please select date';
        }

        setError(err);
        return isValid;
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Holiday</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to='/user/holiday-management'>Holiday Management</Link></li>
                                    <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Holiday Attendance</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card p-3 basicform">
                        <div className="mb-3 titleForm border-bottom">
                            <h4 className="">Holiday</h4>
                        </div>

                        <div className="row card_id">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label className="form-label">Name <span className='text-danger'> *</span></label>
                                        <input type="text" className="form-control" name='name' value={holiday?.name} onChange={handleChange} />
                                        <div className='error'>{error?.name_err}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row card_id">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="date" className="form-label">Date <span className='text-danger'> *</span></label>
                                        <input type="date" className="form-control" id="date" name='date' onChange={handleChange} value={holiday?.date} />
                                        <div className='error'>{error?.date_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="employeeID" className="form-label">Days</label>
                                        <input value={holiday?.day} className="form-control" disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="employeeID" className="form-label">Month</label>
                                        <input
                                            value={new Intl.DateTimeFormat('en-IN', { month: 'long' }).format(new Date(`${holiday?.date}`))}
                                            className="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            {data?._id ? (
                                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label htmlhtmlFor="fileUpload" className="form-label">Status</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="row card_id mt-4 sm-mt-3">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3 d-flex gap-2">
                                        <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>
                                            {disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}
                                        </button>
                                        {!data?._id && (
                                            <button className="btn btn-outline-secondary w-50" type='button' onClick={() => setHoliday({
                                                name: "",
                                                day: dayOfWeek,
                                                date: formattedDate,
                                                month: monthNumber
                                            })}>
                                                Reset
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRight />
            </div>
        </div>
    );
};

export default ManageHoliday;

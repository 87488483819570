import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';
import Header from '../Pages/Header';
import CopyRight from '../Pages/CopyRight';
import Loader from '../Pages/Loader';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Pagination, Search } from '../Table';
import toast from 'react-hot-toast';
import axios from 'axios';
import { PAY_URL } from '../../../BaseURL';
import { getUserDepartment } from '../../../Store/User/Department/UserDepartment';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';
import calculateMonthRange from '../Components/Helper/calculateMonthDays';
import DropDonwlLimit from '../Components/DropDonwlLimit';

const EarningReport = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [entity, setEntity] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [limit, setlimit] = useState(10);
  const [disable, setDisable] = useState(true);
  const [filter, setFilter] = useState({
    month: '',
    startDate: '',
    endDate: '',
  });
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }

    if (disable === true) {
      setEntity([]);
      getEarningReport();
    }
  }, [disable, navigate]);

  useEffect(() => {
    dispatch(getUserDepartment())
  }, []);

  const deapartmentData = useSelector(state => state?.getUserDepartment?.user?.data);

  const commentsData = useMemo(() => {
    let allEmployees = [];

    entity.forEach((record) => {
      if (record?.earnings?.employees) {
        allEmployees.push(...record.earnings.employees);
      }
    });

    if (search) {
      allEmployees = allEmployees.filter((employee) =>
        employee.employee_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        employee.employee_id?.toLowerCase()?.includes(search?.toLowerCase()) ||
        employee.card_no?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }

    if (filter.month !== '') {
      allEmployees = allEmployees.filter((employee) =>
        parseInt(employee.month) === parseInt(filter.month)
      );
    }

    if (filter.startDate && filter.endDate) {
      const startDate = new Date(filter.startDate);
      const endDate = new Date(filter.endDate);

      allEmployees = allEmployees.filter((employee) => {
        const employeeDate = new Date(employee.date);
        const normalizedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
        const normalizedEndDate = new Date(endDate.setHours(23, 59, 59, 999));

        return employeeDate >= normalizedStartDate && employeeDate <= normalizedEndDate;
      });
    }


    setTotalItems(allEmployees.length);
    const paginatedEmployees = allEmployees.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
    return paginatedEmployees;
  }, [currentPage, search, limit, entity, filter.month, filter.startDate, filter.endDate]);


  const getEarningReport = () => {
    const myurl = `${PAY_URL}/user/generate-earning-report`;
    const bodyFormData = new URLSearchParams();
    if (selectedDepartment.length > 0) {
      bodyFormData.append("department", JSON.stringify(selectedDepartment.map(department => department.label)));
    }
    if (selectedType.length > 0) {
      bodyFormData.append("type", JSON.stringify(selectedType.map(type => type.label)));
    }
    axios({
      method: "post",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
      data: bodyFormData,
    }).then((response) => {
      if (response?.data?.success) {
        const data = response?.data?.data;

        const filteredData = data?.filter(e =>
          e.firm.id === localStorage.getItem('PAY_USER_FIRM_ID')
        );
        setEntity(filteredData);
        setDisable(false);
      } else {
        toast.error(response.data.message);
      }
    }).catch((error) => {
      toast.error(error);
      console.log("Errors", error);
    });
  };

  const handleRefresh = () => {
    setSearch('');
    setCurrentPage(1);
    setFilter({ month: '', startDate: '', endDate: '' });
    setSelectedDepartment([]);
    setSelectedType([]);
    setMinDate('');
    setMaxDate('');
    setDisable(true);
  }

  const handleDepartment = (e) => {
    setSelectedDepartment(e);
  }

  const handleType = (e) => {
    setSelectedType(e);
  }

  const handleFilter = (e) => {
    const { name, value } = e.target;

    if (name === "month") {
      setFilter({
        ...filter,
        month: value,
        startDate: '',
        endDate: '',
      });
      setSelectedDepartment([]);
      setSelectedType([]);
      const dateRange = calculateMonthRange(value);
      setMinDate(dateRange.min);
      setMaxDate(dateRange.max);
    } else {
      setFilter({ ...filter, [name]: value });
    }

    getEarningReport();
  };

  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  useEffect(() => {
    getEarningReport();
  }, [filter.month, selectedDepartment, selectedType, filter.startDate, filter.endDate]);

  let counter = (currentPage - 1) * limit + 1;

  const earnTypes = [
    { label: 'Other', id: 1 },
    { label: 'Bonus', id: 2 },
    { label: 'Over Time', id: 3 },
    { label: 'Sunday_Present', id: 4 },
    { label: 'Full_Night_Present', id: 5 },
  ]

  // console.log(filter)
  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">Earning Report Management</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Earning Report Management</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row filter-row justify-content-end">
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className="row">
                <div className="col-md-12 mb-2">
                  <select className="form-select form-control" name="month" value={filter.month} onChange={handleFilter}
                    aria-label="Default select example">
                    <option value=''>Select Month</option>
                    <option value='1'>January</option>
                    <option value='2'>February</option>
                    <option value='3'>March</option>
                    <option value='4'>April</option>
                    <option value='5'>May</option>
                    <option value='6'>June</option>
                    <option value='7'>July</option>
                    <option value='8'>August</option>
                    <option value='9'>September</option>
                    <option value='10'>October</option>
                    <option value='11'>November</option>
                    <option value='12'>December</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-8">
              <div className="row justify-content-end">
                <div className="col-md-12 mb-2">
                  <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                    <div className="seletePage d-flex gap-2 align-items-center ">
                      <p className="mb-0">Rows per page:</p>
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <DropDonwlLimit limit={limit} onLimitChange={(val) => setlimit(val)} />
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <div className="refreshPage">
                            <button className="btn btn-refresh" type='button' onClick={() => setOpenFilter(!openFilter)}
                              aria-controls="example-collapse-text"
                              aria-expanded={openFilter}>
                              <i className="fa-solid fa-filter"></i>
                            </button>

                          </div>
                          <div className="refreshPage">
                            <button className="btn btn-refresh" type='butotn' onClick={handleRefresh}>
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="tableSearch">
                      <form action="">
                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }} />
                        <span className="search">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Collapse in={openFilter} >
            <div className='row filter-row mt-2'>
              <div className='col-md-3 col-lg-3 col-sm-12'>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    {deapartmentData && deapartmentData.length > 0 ? (
                      <>
                        <label className="form-label">Department</label>

                        <MultiSelect
                          name="department"
                          options={deapartmentData.map(e => ({ label: e.name, value: e._id }))}
                          value={selectedDepartment}
                          onChange={handleDepartment}
                          labelledBy="Select Department"
                        />
                      </>
                    ) : (
                      <p>Loading departments...</p>
                    )}
                  </div>

                </div>
              </div>

              <div className='col-md-3 col-lg-3 col-sm-12'>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    {earnTypes && earnTypes?.length > 0 ? (
                      <>
                        <label className="form-label">Type</label>
                        <MultiSelect
                          name="type"
                          options={earnTypes?.map((e) => ({ label: e.label, value: e.id }))}
                          value={selectedType}
                          onChange={handleType}
                          labelledBy="Select Earning Type"
                        />
                      </>
                    ) : (
                      <p>Loading Earning Type...</p>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-md-3 col-lg-3 col-sm-12'>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label className="form-label">Start Date</label>
                    <input type="date" name="startDate" value={filter.startDate} onChange={handleFilter}
                      className='form-control' min={minDate} max={maxDate} />
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-lg-3 col-sm-12'>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label className="form-label">End Date</label>
                    <input type="date" name="endDate" value={filter.endDate} onChange={handleFilter}
                      className='form-control' min={minDate} max={maxDate} />
                  </div>
                </div>
              </div>
            </div>
          </Collapse>

          {disable === false ? (
            <div className="card p-3 ">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>Date</th>
                          <th>VNO.</th>
                          <th>Name</th>
                          <th>ID</th>
                          <th>CardNo</th>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Month</th>
                          <th>Earn_type</th>
                          <th>Amount</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commentsData?.map((elem, i) =>
                          <tr key={i}>
                            <td>{(currentPage - 1) * limit + i + 1}</td>
                            <td>{moment(elem?.date).format('YYYY-MM-DD')}</td>
                            <td>{elem?.voucher_no}</td>
                            <td>{elem?.employee_name}</td>
                            <td>{elem?.employee_id}</td>
                            <td>{elem?.card_no}</td>
                            <td>{elem?.designation}</td>
                            <td>{elem?.department}</td>
                            <td>{elem?.month}</td>
                            <td>{elem?.type}</td>
                            <td>{elem?.amount}</td>
                            <td>{elem?.remarks === '' ? '-' : elem?.remarks}</td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan={9}></td>
                          <th>Total</th>
                          <th>
                            {commentsData?.reduce((total, elem) => total + (elem?.amount || 0), 0)}
                          </th>
                          <td></td>
                        </tr>

                        {commentsData?.length === 0 ? (
                          <tr>
                            <td colSpan="999">
                              <div className="no-table-data">
                                No Data Found!
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>

                  </div>
                  <div className="row align-center mt-3 user-account">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                      <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                        aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                      <div className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate">
                        <Pagination
                          total={totalItems}
                          itemsPerPage={limit}
                          currentPage={currentPage}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <Loader />}

        </div>
        <CopyRight />
      </div>
    </div>
  )
}

export default EarningReport
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';

const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const TransferSalaryModal = ({ transferModal, handleHideTransferModal, title, text, url, handleTransferSuccess }) => {
    const [modalData, setModalData] = useState({ password: '', month: '' });
    const [showEye, setShowEye] = useState(true);
    const [disable, setDisable] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [error, setError] = useState({});

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes} min ${seconds} sec`;
    };

    const handleMonthToChange = (e) => {
        setModalData({ ...modalData, month: e.target.value });
    };

    const getMonthFrom = () => {
        const selectedMonthIndex = parseInt(modalData.month, 10) - 1;
        const prevMonthIndex = (selectedMonthIndex - 1 + 12) % 12;
        return monthNames[prevMonthIndex];
    };

    useEffect(() => {
        if (!transferModal) {
            setModalData({ password: '', month: '' });
            setDisable(false);
            setElapsedTime(0);
        }
    }, [transferModal])

    const handleSubmitSalary = () => {
        if (validation()) {
            setDisable(true);
            const interval = setInterval(() => {
                setElapsedTime((prev) => prev + 1);
            }, 1000);
            setDisable(true);
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('password', modalData.password);
            bodyFormData.append('month', modalData.month);
            bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'))

            axios({
                method: 'post',
                url: `${PAY_URL}/user/${url}`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
            }).then((response) => {
                clearInterval(interval);
                if (response.data.success === true) {
                    toast.success(response.data.message);
                    handleHideTransferModal();
                    setModalData({ password: '', month: '' });
                    handleTransferSuccess();
                } else {
                    toast.error(response.data.message);
                }
                setDisable(false);
                setElapsedTime(0);
            }).catch((error) => {
                clearInterval(interval);
                setDisable(false);
                toast.error(error?.response?.data?.message);
                setElapsedTime(0);
            })
        }
    };

    const validation = () => {
        let isValid = true;
        let err = {};
        if (!modalData.password) {
            isValid = false;
            err['password_err'] = "Please enter password";
        }
        if (!modalData.month) {
            isValid = false;
            err['month_err'] = "Please select month";
        }
        setError(err);
        return isValid;
    }

    return (
        <Modal show={transferModal} onHide={handleHideTransferModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h4 className='text-center mb-3'>{title} As Per Month</h4>
                    <div className='row my-2'>
                        {modalData.month && (
                            <div className='col-12'>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">Month From</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={getMonthFrom()}
                                        readOnly
                                    />
                                </div>
                            </div>
                        )}
                        <div className='col-12 mb-3'>
                            <div className="input-group">
                                <span className="input-group-text">Month To</span>
                                <select
                                    className="form-select form-control"
                                    value={modalData.month}
                                    onChange={handleMonthToChange}
                                >
                                    <option value="" disabled>Select Month</option>
                                    {monthNames.map((month, index) => (
                                        <option key={index} value={index + 1}>{month}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='error'>{error?.month_err}</div>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className="input-group">
                                <span className="input-group-text">Password</span>
                                <input
                                    type={showEye ? "password" : "text"}
                                    className="form-control position-relative"
                                    value={modalData.password}
                                    onChange={(e) => setModalData({ ...modalData, password: e.target.value })}
                                />
                                {showEye ? (<div className='tranfer-eye' onClick={() => { setShowEye(false) }}><i className="fa-solid fa-eye" /></div>) :
                                    (<div className='tranfer-eye' onClick={() => { setShowEye(true) }}><i className="fa-solid fa-eye-slash" /> </div>)}
                            </div>
                            <div className='error'>{error?.password_err}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <p><b>Note:</b> <small>Please select the month for which you would like to transfer the {text}.</small></p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHideTransferModal}>
                    Close
                </Button>
                <Button className='btn btn-success' onClick={handleSubmitSalary} disabled={disable}>
                    {disable ? `Processing... (${formatTime(elapsedTime)})` : 'Transfer'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TransferSalaryModal;



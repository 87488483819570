const calculateMonthRange = (selectedMonth) => {
    const year = new Date().getFullYear(); 
    const month = parseInt(selectedMonth); 

    const firstDay = new Date(year, month - 1, 1); 
    const lastDay = new Date(year, month, 0);
   
    const min = `${firstDay.getFullYear()}-${(firstDay.getMonth() + 1).toString().padStart(2, '0')}-${firstDay.getDate().toString().padStart(2, '0')}`;
    const max = `${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

    return { min, max };
  };


export default calculateMonthRange;
